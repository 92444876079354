export const SESSION_ID = "session_id";
export const ACCOUNT_ID = "account_id";
export const REQUEST_TOKEN = "request_token";
export const THEME_MODE = "theme_mode";
export const TMDB_IMAGE_PATH = "https://image.tmdb.org/t/p";
export const TMDB_API_BASE_URL = "https://api.themoviedb.org/3";
export const BLUE_LOGO =
  "https://fontmeme.com/permalink/220718/a414c5beb5960dd0785090e76a5e2593.png";
export const RED_LOGO =
  "https://fontmeme.com/permalink/220718/4b2aa4dfacbd3e49bb11c8e23fcad6ce.png";
